<template>
  <img
    v-if="bedrijf_thema?.logo && !store.getters.isKantoorPermission"
    :src="bedrijf_thema?.logo"
    :alt="bedrijf_thema?.naam"
    :style="`max-height: ${maxHeight}px;`"
  >
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  bedrijf: String,
  maxHeight: {
    type: [Number, String],
    default: 42,
  },
})

const store = useStore()
const bedrijf_thema = computed(() => {
  if (!props.bedrijf) return null
  return store.state.bedrijf_themas[props.bedrijf]
})
</script>
